import React from 'react';
import { TableHeader } from 'features/common';
import { Pagination, Table, Button } from 'spoton-lib';

import { NoRowsRenderer } from './index';
import { IEmployeeTable } from 'features/OnboardEmployees/types';
import { StatusRenderer } from 'features/OnboardEmployees/components';
import { OnboardingAlert } from '../OnboardingAlert/OnboardingAlert.component';
import { Link } from 'react-router-dom';
import { getRoutePrefix } from 'features/common/utils';

import { useTranslation } from 'react-i18next';
import styles from './EmployeeTable.module.scss';

const ROW_HEIGHT = 72;
const itemsPerPage = 6;
const tableHeight = (itemsPerPage + 1) * ROW_HEIGHT;
const prefix = getRoutePrefix();

const renderChild = (header: string, index: number) => {
  return (
    <Table.Column
      key={index}
      width={400}
      dataKey={header}
      label={header}
      cellRenderer={({ rowData }: { [key: string]: any }) => {
        let isOnboarded = false;
        if (rowData?.integration?.externalId === null) {
          isOnboarded = true;
        }
        const name = `${rowData['firstName']}  ${rowData['lastName']}`;
        if (header === 'Name') {
          return isOnboarded ? (
            <div className={styles.EmployeeTable_needsOnboardingContainer}>
              <span className={styles.EmployeeTable_names}>{name}</span>
              <div
                className={styles.EmployeeTable_needsOnboardingTextContainer}
              >
                <OnboardingAlert className={styles.EmployeeTable_alert} />
              </div>
            </div>
          ) : (
            <span className={styles.EmployeeTable_names}>{name}</span>
          );
        }
        if (header === 'Location') {
          return rowData['location']?.primary?.name;
        }
        if (header === 'Roles') {
          const final = rowData['roles']?.map(
            (role: { name: string }) => role.name,
          );
          return final?.join('/');
        }
        if (header === 'Status') {
          return StatusRenderer(rowData['integration']?.externalId);
        }
        const lower = header.toLowerCase();
        return rowData[lower] ? rowData[lower] : '';
      }}
    />
  );
};

export const EmployeeTable = ({
  flowUrl,
  data,
  onSort,
  sortBy,
  sortDirection,
  pageCount,
  onPageChange,
  currentPage,
  failedToLoad = false,
}: IEmployeeTable): JSX.Element => {
  const { t } = useTranslation();
  const orderedHeaders =
    data.length > 0
      ? [
          'lastName',
          'firstName',
          'email',
          'roles',
          'location',
          'status',
          'extra',
          'employeeId',
        ]
      : [];
  return (
    <div className={styles.EmployeeTable}>
      <div className={styles.EmployeeTable_header}>
        <TableHeader data-testid="onboardingTable-header">
          {t('EMPLOYEE_ONBOARDING_HEADER')}
        </TableHeader>
        <Link
          style={{ textDecoration: 'none' }}
          to={{
            pathname: `${prefix}/manage-employees`,
            state: flowUrl,
          }}
        >
          <Button isLoading={false} variant="secondary">
            {t('EMPLOYEE_ONBOARDING_BUTTON_TITLE')}
          </Button>
        </Link>
      </div>
      <Table
        height={tableHeight}
        rowHeight={ROW_HEIGHT}
        rowGetter={({ index }) => data[index]}
        keyGetter={({ index }) => index}
        rowCount={data.length}
        hasError={failedToLoad}
        sort={onSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        noRowsRenderer={() => NoRowsRenderer({ failedToLoad, isMobile: false })}
      >
        {orderedHeaders.map((header, index: any) => {
          const upperCaseHeader =
            header.slice(0, 1).toUpperCase() + header.slice(1);
          header = upperCaseHeader;
          if (header === 'Extra') return;
          if (header === 'Integration') return;
          if (header === 'LastName') return;
          if (header === 'EmployeeId') return;
          if (header === 'FirstName') header = 'Name';
          return renderChild(header, index);
        })}
      </Table>
      {pageCount > 1 ? (
        <Pagination
          data-testid="employeeTable-pagination"
          currentPage={currentPage}
          numberOfPages={pageCount}
          onPageChange={onPageChange}
          className={styles.EmployeeTable_pagination}
        />
      ) : null}
    </div>
  );
};

/**
 
cell and header alignment in figma
some are centered and some are right and left
need to choose one alignment..currently ive chosen center
 */
