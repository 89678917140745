import React from 'react';
import { InfoRow } from 'features/common';
import {
  RenderAvatarCell,
  StatusRenderer,
} from 'features/OnboardEmployees/components';
import { Card } from 'spoton-lib';

import { IEmployeeItem } from 'features/OnboardEmployees/types';

import styles from './EmployeeTile.module.scss';
export const EmployeeTile = (employee: IEmployeeItem): JSX.Element => {
  const { email, location, roles, type, integration } = employee;
  return (
    <Card
      elevation={1}
      className={styles.EmployeeTile}
      data-testid="EmployeeTile-component"
    >
      <RenderAvatarCell {...employee} className={styles.EmployeeTile_avatar} />
      <InfoRow type={type} data={email} labelKey="EMPLOYEE_TABLE_LABEL_EMAIL" />
      <InfoRow
        type={type}
        data-testid="EmployeeTile-roles"
        data={roles
          .map(({ name }) => name)
          .sort()
          .join('/')}
        labelKey="Roles"
      />
      <InfoRow
        type={type}
        data={location?.primary?.name}
        labelKey="EMPLOYEE_TABLE_LABEL_LOCATION"
      />

      <InfoRow
        labelKey="EMPLOYEE_TABLE_LABEL_STATUS"
        data={StatusRenderer(integration?.externalId)}
      />
    </Card>
  );
};
