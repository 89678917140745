import React from 'react';
import { useLocation } from 'react-router-dom';

export interface ILocation {
  state?: string;
}
export const TermsAndConditions = (): JSX.Element => {
  const flowUrl = useLocation() as ILocation;
  return (
    <iframe
      style={{ width: '100vw', height: '100vh' }}
      src={flowUrl?.state}
      title="TermsAndConditions"
    ></iframe>
  );
};
