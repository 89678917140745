import React from 'react';
import { EmployeeTile, Sorter, TableHeader, TableContent } from './index';
import { TableEmpty } from 'features/common/components';
import { Pagination, TableError, Button } from 'spoton-lib';
import { Link } from 'react-router-dom';
import { getRoutePrefix } from 'features/common/utils';

import { Trans } from 'react-i18next';
import { IEmployeeTable, IEmployeeItem } from 'features/OnboardEmployees/types';
import styles from './EmployeeTableMobile.module.scss';
import { useTranslation } from 'react-i18next';

const prefix = getRoutePrefix();

export const EmployeeTableMobile = ({
  flowUrl,
  data,
  currentPage,
  pageCount,
  sortBy,
  sortDirection,
  onSort,
  onPageChange,
  failedToLoad = false,
}: IEmployeeTable): JSX.Element => {
  const hasData = !!data.length;
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.TableMobile}>
        <div className={styles.TableMobile_mobileHeader}>
          <TableHeader data-testid="onboardingTable-header">
            {t('EMPLOYEE_ONBOARDING_HEADER')}
          </TableHeader>
          <Link
            style={{ textDecoration: 'none' }}
            to={{
              pathname: `${prefix}/manage-employees`,
              state: flowUrl,
            }}
          >
            <Button isLoading={false} variant="secondary">
              {t('EMPLOYEE_ONBOARDING_BUTTON_TITLE')}
            </Button>
          </Link>
        </div>
        {!hasData || failedToLoad ? null : (
          <Sorter<IEmployeeItem>
            sortBy={sortBy}
            onSort={onSort}
            sortDir={sortDirection}
          />
        )}
      </div>
      <TableContent>
        {hasData && !failedToLoad ? (
          (data as IEmployeeItem[]).map((employee: IEmployeeItem) => (
            <EmployeeTile
              type="small"
              {...employee}
              key={employee.employeeId}
            />
          ))
        ) : (
          <div>
            {failedToLoad && (
              <TableError
                errorBody={<Trans i18nKey="EMPLOYEE_TABLE_ERROR_LABEL" />}
              />
            )}
            {!hasData && !failedToLoad && (
              <TableEmpty className={styles.TableMobile_emptyTable} />
            )}
          </div>
        )}
      </TableContent>
      {pageCount > 1 && hasData && !failedToLoad ? (
        <div className={styles.PaginationBottom}>
          <Pagination
            data-testid="employeeTable-pagination_mobile"
            currentPage={currentPage}
            numberOfPages={pageCount}
            onPageChange={onPageChange}
            className={styles.Pagination}
          />
        </div>
      ) : null}
    </div>
  );
};
