// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./fonts/Poppins-Regular.woff";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*{box-sizing:border-box}body{margin:0;font-family:Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}@font-face{font-weight:normal;font-family:Poppins;font-style:normal;src:local(\"Poppins\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff\")}\n", "",{"version":3,"sources":["webpack://src/index.module.scss"],"names":[],"mappings":"AAIA,EACI,qBAAsB,CACzB,KAGG,QAAS,CACT,8JAEc,CACd,kCAAmC,CACnC,iCAAkC,CACrC,KAGG,8EACa,CAChB,WAGG,kBAAmB,CACnB,mBAAoB,CACpB,iBAAkB,CAClB,2EAAuE","sourcesContent":["// disabling it because stylelint show false-positive for body/code\n// and I dont know how to config/disable it\n/* stylelint-disable */\n\n* {\n    box-sizing: border-box;\n}\n\nbody {\n    margin: 0;\n    font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,\n        Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n        sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n        monospace;\n}\n\n@font-face {\n    font-weight: normal;\n    font-family: Poppins;\n    font-style: normal;\n    src: local('Poppins'), url('fonts/Poppins-Regular.woff') format('woff');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
