import React from 'react';
import { Trans } from 'react-i18next';

export const StatusRenderer = (isOnboarded: boolean): JSX.Element => {
  return (
    <div data-testid="status-renderer">
      {isOnboarded ? (
        <Trans i18nKey="EMPLOYEE_ONBOARDING_ONBOARDED" />
      ) : (
        <Trans i18nKey="EMPLOYEE_ONBOARDING_NOT_ONBOARDED" />
      )}
    </div>
  );
};
