import React from 'react';
import clsx from 'clsx';

import { Trans } from 'react-i18next';
import { IInfoRow } from './infoRow.interface';
import styles from './InfoRow.module.scss';

export const InfoRow = ({
  data,
  labelKey,
  type = 'normal',
  variant = 'horizontal',
  className,
}: IInfoRow): JSX.Element => {
  const variants = variant.split(' ');
  const isVertical = variants.includes('vertical');
  const isDesktop = variants.includes('desktop');
  return (
    <div
      className={clsx(
        styles.InfoRow,
        {
          [styles.InfoRow_vertical]: isVertical,
          [styles.InfoRow_desktop]: isDesktop,
        },
        className,
      )}
    >
      <div
        data-testid="InfoRow-component_label"
        className={clsx(styles.InfoRow_label, {
          [styles.InfoRow_label___small]: type === 'small',
          [styles.InfoRow_label___desktop]: isDesktop,
        })}
      >
        <Trans i18nKey={labelKey} />
      </div>
      <div
        data-testid="InfoRow-component_data"
        className={clsx(styles.InfoRow_data, {
          [styles.InfoRow_data___vertical]: isVertical,
          [styles.InfoRow_data___desktop]: isDesktop,
          [styles.InfoRow_data___small]: type === 'small',
        })}
      >
        {data}
      </div>
    </div>
  );
};
