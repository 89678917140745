import {
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import { getConfigVar } from 'features/common/utils';

export const HTTP_STATUS_CODES = {
  UNAUTHORIZED: 401,
};
const baseUrl = getConfigVar('REACT_APP_PAYROLL_BFF_BASE_URL');

export const baseQuery = fetchBaseQuery({
  baseUrl: `${baseUrl}/api/v1`,
  credentials: 'include',
});

export const baseQueryWithInterceptor: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
    const nextUrl = window.location.href;

    window.location.href = `${getConfigVar(
      'REACT_APP_CORE_LOGIN_BASE_URL',
    )}/home?next=${nextUrl}`;
  }

  return result;
};
