import React from 'react';
import { LoadingOverlay } from 'spoton-lib';

import { useGetRestaurantEmployeesAddQuery } from 'redux/reducers/Payroll/api';
import { EmployeeTable, EmployeeTableMobile, TableWrapper } from '../index';
import { IEmployeeItem } from 'features/OnboardEmployees/types';
import { employeeFilter } from 'features/common/utils/';

const itemsPerPage = 6;

export const EmployeeOnboarding = (): JSX.Element => {
  const { data, isLoading, isError } = useGetRestaurantEmployeesAddQuery();

  if (isLoading && !isError) {
    return <LoadingOverlay size="sm" hasBackgroundBlur />;
  }
  const finalEmployeeList = employeeFilter(data ? data.employees : []);
  const flowUrl = data?.flowUrl;

  return (
    <TableWrapper
      dataCount={data ? data.employees?.length : 0}
      itemsPerPage={itemsPerPage}
      // TODO: Change it when we have paginated api
      data={data ? (finalEmployeeList as IEmployeeItem[]) : []}
      flowUrl={flowUrl || ''}
      desktopComponent={EmployeeTable}
      mobileComponent={EmployeeTableMobile}
      isFetching={isLoading}
    />
  );
};
