import React from 'react';
import { useSort } from 'features/common/hooks';

import { ITableWrapper } from './tableWrapper.interface';
import './TableOverrides.css';

export const isMobile = window.matchMedia('(max-width: 768px)').matches;

export const TableWrapper = ({
  data,
  dataCount = 0,
  itemsPerPage,
  mockMobile,
  isFetching,
  onPageChange,
  failedToLoad = false,
  mobileComponent: Mobile,
  desktopComponent: Desktop,
  flowUrl,
}: ITableWrapper): JSX.Element => {
  const {
    sortedData,
    currentPage,
    sortBy,
    setCurrentPage,
    sortDirection,
    onSort,
  } = useSort({
    data,
    itemsPerPage,
  });
  const commonProps = {
    flowUrl,
    currentPage,
    isFetching,
    failedToLoad,
    onSort,
    sortBy,
    sortDirection,
    data: sortedData,
    onPageChange: handlePageChange,
    pageCount: Math.ceil(dataCount / itemsPerPage),
  };

  function handlePageChange(page: number) {
    setCurrentPage(page);

    if (onPageChange) {
      onPageChange(page);
    }
  }

  if (mockMobile ?? isMobile) {
    return <Mobile {...commonProps} />;
  }

  return <Desktop {...commonProps} />;
};
