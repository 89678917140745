/**
 * A helper for getting an env variable.
 *
 * When our project gets built for production we switch from the usual build
 * time ENV vars to run time variables injected via our build scripts, nginx,
 * and including it in the project's header.
 *
 * This allows us to set ENV vars whenever we want without the need to rebuild
 * and deploy, however it does mean a little extra work to get the right thing.
 * This method helps take out the guesswork, and gives a nice warning if a
 * variable is not set properly.
 *
 * @see /public/index.html
 * @see /env.sh
 */
export function getConfigVar(key: string): string | undefined {
  // workaround for process being undefined in storybook
  let env = (process && process.env) || {}; // default env
  const isRuntimeEnv = env.NODE_ENV === 'production';

  if (isRuntimeEnv) {
    env = (window as any)._payroll_env_;

    if (!env) {
      console.warn(`[Warn] Environment _payroll_env_ is undefined`);

      return undefined;
    }
  }

  if (!env[key]) {
    // don't use logging util because we always want this warning on
    console.warn(`[Warn] Environment variable '${key}' is undefined`);

    return undefined;
  }

  return env[key];
}

/**
 * A helper for checking if the environment is prod or staging
 * @returns {boolean}
 */
export const getIsProdOrStaging = (): boolean => {
  const envs = ['production', 'staging'];

  return envs.includes(getConfigVar('REACT_APP_NODE_ENV') || '');
};

/**
 * Get the correct path prefix for routes
 *
 * This is necessary because when we mount the application as a micro front end,
 * the application will be mounted at a specific route, not found in this
 * application. If we do not specify a prefix, routes will not match and you
 * application will appear to be working but broken!
 *
 * Implemented as a function to avoid refactors later
 */
export function getRoutePrefix(): string {
  return (getConfigVar('REACT_APP_URL_PREFIX') as string) || '';
}
