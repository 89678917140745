import { IEmployeeItem } from 'features/OnboardEmployees/types';

export const employeeFilter = (
  arg: Array<IEmployeeItem>,
): Array<IEmployeeItem> => {
  const notOnboardedList = arg?.filter(
    (employee: IEmployeeItem) => employee.integration.externalId === null,
  );

  const onboardedList = arg?.filter(
    (employee: IEmployeeItem) => employee.integration.externalId !== null,
  );

  const finalEmployeeList = notOnboardedList?.concat(onboardedList);
  return finalEmployeeList;
};
