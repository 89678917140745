import React from 'react';
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import {
  ErrorBoundaryGeneric,
  getConfigVar,
  getIsProdOrStaging,
} from 'features';
import { history, configureAppStore } from './redux/config';
import { App } from './app';

// Global styles
import './index.module.scss';

// Setup for Datadog RUM Browser Monitoring
if (getIsProdOrStaging()) {
  datadogRum.init({
    applicationId: getConfigVar('REACT_APP_DATADOG_APPLICATION_ID') as string,
    clientToken: getConfigVar('REACT_APP_DATADOG_CLIENT_TOKEN') as string,
    site: 'datadoghq.com',
    env: getConfigVar('REACT_APP_NODE_ENV') as string,
    service: 'payroll-web',
    version: getConfigVar('REACT_APP_VERSION'),
    trackInteractions: true,
  } as RumInitConfiguration);
  datadogRum.startSessionReplayRecording();
}

const RootComponent = (): JSX.Element => (
  <React.StrictMode>
    <Provider store={configureAppStore()}>
      <Router history={history}>
        <ErrorBoundaryGeneric>
          <App />
        </ErrorBoundaryGeneric>
      </Router>
    </Provider>
  </React.StrictMode>
);

if (!getIsProdOrStaging()) {
  const container = document.getElementById('root');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(container!);
  root.render(RootComponent());
}

export default RootComponent;
