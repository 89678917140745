import React, { KeyboardEvent, useState } from 'react';
import { Divider, Icon, SortDirectionType } from 'spoton-lib';
import { TableHeader } from '../TableHeader/TableHeader.component';
import { SortOptions } from './SortOptions/SortOptions.component';

import { Trans } from 'react-i18next';
import clsx from 'clsx';

import { sortOptions } from './config';
import { ISorter, ISortOption } from 'features/common/types';

import { IEmployeeItem } from 'features/OnboardEmployees/types';

import styles from './Sorter.module.scss';

const SORT_DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC',
};

type ISorterInterface = IEmployeeItem;

export const Sorter = <T extends ISorterInterface>({
  onSort,
  sortBy,
  sortDir,
  customSortOptions,
}: ISorter<T>): JSX.Element => {
  const options = (customSortOptions ?? sortOptions) as ISortOption<T>[];
  const selectedOption = options.find(o => o.prop === sortBy);
  const sortingLabel = selectedOption?.i18key;

  const [isSortMenuOpen, setMenuOpen] = useState(false);

  const handleSideMenu = (
    ev?: React.MouseEvent<HTMLElement> | KeyboardEvent<HTMLDivElement>,
  ) => {
    ev?.stopPropagation();
    setMenuOpen(open => !open);
  };

  const handleKeyDown = (ev: KeyboardEvent<HTMLDivElement>) => {
    if (ev.key == 'Enter') {
      handleSideMenu(ev);
    }
  };

  const handleOptionSelect = (
    sortBy: string,
    sortDirection: SortDirectionType,
  ) => {
    const selectedOption = options.find(o => o.prop === sortBy);
    const accessor = selectedOption?.accessor;

    handleSideMenu();
    onSort({
      accessor,
      sortBy,
      sortDirection,
    });
  };

  return (
    <div
      role="button"
      tabIndex={0}
      className={styles.Sorter}
      onClick={handleSideMenu}
      onKeyDown={handleKeyDown}
    >
      <div>
        <div className={styles.Sorter_header}>
          <Trans i18nKey={'SORT_LABEL_SORT_BY'} />
        </div>
        <div className={styles.Sorter_subheader}>
          <Trans i18nKey={sortingLabel} />
          <span className={styles.Sorter_sortDirection}>
            {sortDir === SORT_DIRECTION.ASC ? (
              <Trans i18nKey="SORT_INFO_ASCENDING" />
            ) : (
              <Trans i18nKey="SORT_INFO_DESCENDING" />
            )}
          </span>
        </div>
      </div>
      <Icon name="ChevronRightIcon" />
      <div
        data-testid="Sorter-overlay___menu"
        className={clsx(styles.SorterOverlay, {
          [styles.SorterOverlay___open]: isSortMenuOpen,
        })}
      >
        <div
          role="button"
          tabIndex={0}
          className={styles.SorterOverlay_content}
          onKeyDown={handleKeyDown}
          onClick={handleSideMenu}
        >
          <Icon name="CloseIcon" size={22} />
          <TableHeader
            className={styles.SorterOverlay_header}
            data-testid="sorter-header"
          >
            <Trans i18nKey={'SORT_LABEL_SORT_BY'} />
          </TableHeader>
        </div>
        <Divider className={styles.SorterOverlay_divider} />
        <SortOptions
          initialSortBy={sortBy}
          sortOptions={options}
          onSelect={handleOptionSelect}
        />
      </div>
    </div>
  );
};
