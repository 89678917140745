import React from 'react';
import { Text, TextTypes } from 'spoton-lib';

import { IPropTypes, IState } from './ErrorBoundaryGeneric.types';

// NOTE: using class based component ONLY because we need support for error boundaries
export class ErrorBoundaryGeneric extends React.Component<IPropTypes> {
  state: IState = { error: null };

  componentDidCatch(error: Error): void {
    this.setState({ error });
  }

  renderGenericError = (): JSX.Element => {
    return (
      <Text type={TextTypes.H2} className={this.props.className}>
        Oops! Something went wrong.
      </Text>
    );
  };

  render(): React.ReactNode {
    if (this.state.error) {
      return this.renderGenericError();
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundaryGeneric;
