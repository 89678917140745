import React from 'react';
import clsx from 'clsx';

import { ITableSubHeader } from './tableSubheader.interface';
import styles from './TableSubheader.module.scss';

export const TableSubheader = ({
  className,
  ...props
}: ITableSubHeader): JSX.Element => (
  <div {...props} className={clsx(styles.Subheader, className)} />
);
