import React from 'react';
import { RowButton, Radio } from 'spoton-lib';
import { Trans } from 'react-i18next';

import { IRadioRow } from './radioRow.interface';
import styles from './RadioRow.module.scss';

export const RadioRow = ({
  name,
  i18nKey,
  isChecked,
  onClick,
}: IRadioRow): JSX.Element => {
  const handleClick = (ev: React.SyntheticEvent) => {
    ev.stopPropagation();
    onClick();
  };

  return (
    <RowButton
      data-testid="RadioRow-button"
      onClick={handleClick}
      className={styles.RadioRow_rowButton}
      title={
        <div className={styles.RadioRow_rowButton___text}>
          <Trans i18nKey={i18nKey} />
        </div>
      }
      leftElement={
        <Radio
          data-testid="RadioRow-radio"
          name={name}
          checked={isChecked}
          className={styles.RadioRow_rowButton___radio}
          // this prop is required although it
          // does nothing
          onChange={() => false}
        />
      }
    />
  );
};
