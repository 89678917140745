import React from 'react';
import { Text } from 'spoton-lib';
import { Trans } from 'react-i18next';

import clsx from 'clsx';
import emptyEmployeeTable from 'features/OnboardEmployees/assets/employeeTableEmpty.png';

import { ITableEmpty } from './tableEmpty.interface';
import styles from './TableEmpty.module.scss';

export const TableEmpty = ({ className }: ITableEmpty): JSX.Element => {
  const imgSource = emptyEmployeeTable;

  return (
    <div className={styles.EmptyTable} data-testid="TableEmpty-component">
      <div className={clsx(styles.EmptyTable_imageWrapper, className)}>
        <img
          alt="empty table"
          src={imgSource}
          className={styles.EmptyTable_img}
          data-testid="TableEmpty-img"
        />
      </div>
      <Text type="p" className={styles.EmptyTable_info}>
        <Trans i18nKey="EMPLOYEE_TABLE_EMPTY_LABEL" />
      </Text>
    </div>
  );
};
