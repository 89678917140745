// import { configureStore } from '@reduxjs/toolkit';

// import { createBrowserHistory } from 'history';
// import { rtkQueryErrorLogger } from './middleware';
// import { payrollSlice } from './reducers/Payroll/api';

// export const history = createBrowserHistory();

// export const store = configureStore({
//     reducer: {
//         [payrollSlice.reducerPath]: payrollSlice.reducer,
//     },
//     middleware: getDefaultMiddleware =>
//         getDefaultMiddleware().concat([
//             payrollSlice.middleware,
//             rtkQueryErrorLogger,
//         ]),
// });

import { configureStore, EnhancedStore } from '@reduxjs/toolkit';

import { createBrowserHistory } from 'history';
import { rtkQueryErrorLogger } from './middleware';
import { payrollSlice } from './reducers/Payroll/api';

export const history = createBrowserHistory();

export const configureAppStore = (): EnhancedStore<any, any> =>
  configureStore({
    reducer: {
      [payrollSlice.reducerPath]: payrollSlice.reducer,
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat([
        payrollSlice.middleware,
        rtkQueryErrorLogger,
      ]),
  });
