import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { ITranslation } from 'i18nConfig';
import * as features from 'features';

// we will use the `common` namespace as the default in this case, althought
// that may change.
const defaultNamspace = 'common';

// extract translations from features
// TODO Find keys with `^*Translations$` in the string
const translations: ITranslation[] = Object.keys(features)
  .filter(key => /^\w+Translations$/.test(key))
  .map(key => (features as { [key: string]: any })[key]);

// combine all those keys into a resources object that we know how to read
const resources = translations.reduce(
  (acc, current: ITranslation) => {
    return {
      en: {
        ...acc.en,
        [current.namespace]: current.translations.en,
      },
      es: {
        ...acc.es,
        [current.namespace]: current.translations.es,
      },
    };
  },
  { en: {}, es: {} },
);

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    defaultNS: defaultNamspace,
    // lng: 'en', // leave out for browser detection
    fallbackLng: 'en',
    debug: typeof jest === undefined, // false for testing environment

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    detection: {
      // order and from where user language should be detected
      order: [
        'navigator',
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'htmlTag',
        'path',
        'subdomain',
      ],

      // keys or params to lookup language from
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language
      caches: [],
      excludeCacheFor: [],
    },
  });

export default i18n;
