import { isRejectedWithValue } from '@reduxjs/toolkit';
import { showToast } from 'spoton-lib';
import type { Middleware } from 'redux/index.d';

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware = () => next => action => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    console.warn('We got a rejected action!');
    showToast({
      variant: 'danger',
      title: 'Error',
      content: action.error.message,
    });
  }

  return next(action);
};
