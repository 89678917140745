import React, { useEffect, useState } from 'react';
import { LoadingOverlay } from 'spoton-lib';

export const DelayedSpinner = (): JSX.Element | null => {
  const [shouldDisplaySpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowSpinner(true), 750);

    return () => clearTimeout(timer);
  });

  return shouldDisplaySpinner ? <LoadingOverlay size="sm" /> : null;
};
