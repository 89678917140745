import React from 'react';
import styles from '../TermsOfService/TermsOfService.module.scss';
export const GustoAuthentication = (): JSX.Element => {
  const monolithUrl = 'https://dolceclock.com';
  return (
    <div className={styles.TermsOfService_component}>
      <span className={styles.TermsOfService_termsOfServHeader}>
        Gusto Authentication is required.
      </span>
      <div className={styles.TermsOfService_mainTextContainer}>
        <p className={styles.TermsOfService_paragraphText}>
          You need to re-authenticate with Gusto in order to access this page.
          In order to do that please access Dolce Payroll Mapping from{' '}
          <a href={monolithUrl}>here</a>.
        </p>
      </div>
    </div>
  );
};
