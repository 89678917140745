import { ISortOption } from 'features/common/types';
import { IEmployee } from 'features/OnboardEmployees';

// todo: extract this as employee table when
// payroll config are changed
export const sortOptions: ISortOption<IEmployee>[] = [
  {
    id: 'name',
    prop: 'name',
    i18key: 'EMPLOYEE_TABLE_LABEL_NAME',
    accessor: (employee: IEmployee) =>
      `${employee.firstName} ${employee.lastName}`,
  },
  {
    id: 'email',
    prop: 'email',
    i18key: 'EMPLOYEE_TABLE_LABEL_EMAIL',
  },
  {
    id: 'role',
    prop: 'role',
    i18key: 'EMPLOYEE_TABLE_LABEL_ROLE',
    accessor: (employee: IEmployee) =>
      employee.titles
        .map(t => t.name)
        .sort()
        .join(', '),
  },
  {
    id: 'location',
    prop: 'locations',
    i18key: 'EMPLOYEE_TABLE_LABEL_LOCATION',
    accessor: (employee: IEmployee) => employee.locations.sort().join(', '),
  },
  {
    id: 'status',
    prop: 'gustoEmployee.onboarded',
    i18key: 'EMPLOYEE_TABLE_LABEL_STATUS',
  },
];
