export const deepFind = <T>(obj: T, path: string): T | undefined => {
  const paths = path.split('.');
  let current = { ...obj };

  for (let i = 0; i < paths.length; ++i) {
    if (current[paths[i] as keyof T] === undefined) {
      return undefined;
    } else {
      // https://stackoverflow.com/questions/71829024/cant-assign-to-nonnullablet-even-though-i-checked-that-value-is-defined
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      current = current[paths[i] as keyof T];
    }
  }

  return current;
};
