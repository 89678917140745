import React from 'react';
import clsx from 'clsx';

import { ITableHeader } from './tableHeader.interface';
import styles from './TableHeader.module.scss';

export const TableHeader = ({
  className,
  ...props
}: ITableHeader): JSX.Element => (
  <div {...props} className={clsx(styles.Header, className)} />
);
