import React from 'react';
import styles from './TermsOfService.module.scss';

const paraOne =
  'This Embedded Payroll Service Agreement (this “Agreement”) sets forth the terms and conditions under which Gusto, Inc. (“Gusto”) agrees to provide eligible companies (each, a “Company”), in addition to Companies’ eligible employees and independent contractors (each, an “Individual User”) with certain payroll calculation, payment, and related tax reporting services made available through third-party platform providers (each, a “Platform Provider”) (collectively, the “Embedded Payroll Service”).';
const paraTwo =
  'You understand and agree that Gusto is a separate entity from the Platform Provider. As such, (i) Gusto cannot control and is not responsible for the actions, errors, or omissions of any Platform Provider; and (ii) Platform Provider liability for any such actions, errors, or omissions would be governed by your End User Agreement (“EUA”) with such Platform Provider.';
const paraThree =
  'Gusto provides Platform Providers with the ability to offer and assist you with your registration, access, and use of the Embedded Payroll Service. You understand and agree that your Platform Provider may (i) assist your registration to the Embedded Payroll Service by sharing information about you to Gusto; and (ii) conduct activity on your behalf, provided that it does so in each case in accordance with your agreement(s) with such Platform Provider.';

export const TermsOfServiceText = (): JSX.Element => {
  return (
    <div>
      <span className={styles.TermsOfService_termsOfServHeader}>
        Terms Of Service
      </span>
      <div className={styles.TermsOfService_mainTextContainer}>
        <span className={styles.TermsOfService_termsOfServSubHeadOne}>
          Embedded Payroll Service Agreement
        </span>
        <p className={styles.TermsOfService_paragraphText}>{paraOne}</p>
      </div>
      <div>
        <span className={styles.TermsOfService_termsOfServSubHeadOne}>
          Relationship to Platform Providers
        </span>
        <p className={styles.TermsOfService_paragraphText}>{paraTwo}</p>
      </div>
      <div>
        <span className={styles.TermsOfService_termsOfServSubHeadOne}>
          Platform Providers Manage Your Gusto Account
        </span>
        <p className={styles.TermsOfService_paragraphText}>{paraThree}</p>
      </div>
    </div>
  );
};
