// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TooltipIcon_Tooltip_content__WlNc_{max-width:unset}.TooltipIcon_Tooltip_icon__21qtL{cursor:pointer}.TooltipIcon_Tooltip_text__2zqRt{display:inline-block;width:calc(100% - 22px);overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.TooltipIcon_Tooltip_wrapper__2FSnh{display:flex}\n", "",{"version":3,"sources":["webpack://src/features/OnboardEmployees/components/TooltipIcon/TooltipIcon.module.scss"],"names":[],"mappings":"AACE,oCACE,eAAgB,CACjB,iCAGC,cAAe,CAChB,iCAGC,oBAAqB,CACrB,uBAAwB,CACxB,eAAgB,CAChB,kBAAmB,CACnB,sBAAuB,CACxB,oCAGC,YAAa","sourcesContent":[".Tooltip {\n  &_content {\n    max-width: unset;\n  }\n\n  &_icon {\n    cursor: pointer;\n  }\n\n  &_text {\n    display: inline-block;\n    width: calc(100% - 22px);\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n  }\n\n  &_wrapper {\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Tooltip_content": "TooltipIcon_Tooltip_content__WlNc_",
	"Tooltip_icon": "TooltipIcon_Tooltip_icon__21qtL",
	"Tooltip_text": "TooltipIcon_Tooltip_text__2zqRt",
	"Tooltip_wrapper": "TooltipIcon_Tooltip_wrapper__2FSnh"
};
export default ___CSS_LOADER_EXPORT___;
