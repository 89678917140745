import { createApi } from '@reduxjs/toolkit/query/react';

import { IEmployeeAdd } from 'features/OnboardEmployees';
import { baseQueryWithInterceptor } from 'redux/utils';
import { IRunPayrollUrlResponse } from 'features/RunPayroll';

interface IServiceTerms {
  latestTermsRetrieved: boolean;
  mandatory: boolean;
  errorCode?: number;
}

export const payrollSlice = createApi({
  reducerPath: 'payroll',

  baseQuery: baseQueryWithInterceptor,
  tagTypes: ['TOS'],
  endpoints: builder => ({
    getRestaurantEmployees: builder.query<IEmployeeAdd, void>({
      query: () => '/payrolls/restaurant-employees',
    }),
    getRestaurantEmployeesAdd: builder.query<IEmployeeAdd, void>({
      query: () => `/payrolls/restaurant-employees-add?status=All`,
    }),

    getRunPayrollUrl: builder.query<IRunPayrollUrlResponse[], void>({
      query: () => '/payrolls/flows/payroll',
    }),

    getServiceTermsCheck: builder.query<IServiceTerms, void>({
      query: () => '/payrolls/service-terms-retrieve',
      providesTags: ['TOS'],
    }),
    updateServiceTerms: builder.mutation<void, void>({
      query: () => {
        return {
          url: `/payrolls/terms-of-service`,
          method: 'POST',
        };
      },
      invalidatesTags: ['TOS'],
    }),
    getRunOffCyclePayroll: builder.query<IRunPayrollUrlResponse[], void>({
      query: () => '/payrolls/flows/off-cycle-payroll',
    }),
  }),
  refetchOnMountOrArgChange: true,
});
export const {
  useGetRestaurantEmployeesQuery,
  useGetRestaurantEmployeesAddQuery,
  useGetRunPayrollUrlQuery,
  useGetServiceTermsCheckQuery,
  useUpdateServiceTermsMutation,
  useGetRunOffCyclePayrollQuery,
} = payrollSlice;
