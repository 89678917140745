import React, { SyntheticEvent, useState } from 'react';
import { RadioRow } from './RadioRow.component';
import { Button, Divider, SortDirectionType } from 'spoton-lib';

import { Trans } from 'react-i18next';
import { ISortOptions } from './sortOptions.interface';

import styles from './SortOptions.module.scss';

export const SortOptions = <T,>({
  initialSortBy,
  onSelect,
  sortOptions,
}: ISortOptions<T>): JSX.Element => {
  const [state, setState] = useState({
    sortBy: initialSortBy,
    sortDirection: 'ASC' as SortDirectionType,
  });

  const { sortBy, sortDirection } = state;

  const handleOptionSelect = (sortBy: string) => {
    setState(state => ({ ...state, sortBy }));
  };

  const handleDirectionSelect = (sortDirection: SortDirectionType) => {
    setState(state => ({ ...state, sortDirection }));
  };

  const handleApply = (ev: SyntheticEvent<Element, Event> | undefined) => {
    ev?.stopPropagation();

    const { sortBy, sortDirection } = state;
    onSelect(sortBy as string, sortDirection);
  };

  return (
    <div data-testid="SortOptions-component">
      {sortOptions.map(option => (
        <RadioRow
          key={option.id}
          name={option.id}
          i18nKey={option.i18key}
          isChecked={sortBy === option.prop}
          onClick={() => handleOptionSelect(option.prop)}
        />
      ))}
      <Divider className={styles.SortOptions_divider} />
      <RadioRow
        name="ascendingSort"
        i18nKey="SORT_LABEL_ASCENDING"
        isChecked={sortDirection === 'ASC'}
        onClick={() => handleDirectionSelect('ASC')}
      />
      <RadioRow
        name="descendingSort"
        i18nKey="SORT_LABEL_DESCENDING"
        isChecked={sortDirection === 'DESC'}
        onClick={() => handleDirectionSelect('DESC')}
      />
      <Divider className={styles.SortOptions_divider} />
      <div className={styles.SortOptions_buttonWrapper}>
        <Button
          data-testid="SortOptions-button___apply"
          onClick={handleApply}
          className={styles.SortOptions_applyButton}
        >
          <Trans i18nKey="SORT_LABEL_APPLY" />
        </Button>
      </div>
    </div>
  );
};
