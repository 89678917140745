import React from 'react';
import { Icon } from 'spoton-lib';
import { Trans } from 'react-i18next';
import { IOnboardingAlert } from './onboardingAlert.interface';

export const OnboardingAlert = ({
  className,
}: IOnboardingAlert): JSX.Element => {
  return (
    <div data-testid="Onboarding-alert" className={className}>
      <Icon name="DangerSolidIconTriangle" size={16} />
      <span>
        <Trans i18nKey="EMPLOYEE_ONBOARDING_NEEDS_ONBOARDING" />
      </span>
    </div>
  );
};
