import React from 'react';
import { TableEmpty } from 'features/common/components';
import { TableError } from 'spoton-lib';
import { Trans } from 'react-i18next';

interface INoRowsRenderer {
  failedToLoad: boolean;
  isMobile: boolean;
}

export const NoRowsRenderer = ({
  failedToLoad = false,
  isMobile = false,
}: INoRowsRenderer): JSX.Element =>
  failedToLoad ? (
    <TableError
      data-testid="TableError-component"
      errorBody={<Trans i18nKey="EMPLOYEE_TABLE_ERROR_LABEL" />}
    />
  ) : (
    <TableEmpty isMobile={isMobile} />
  );
