import styles from './App.module.scss';
import clsx from 'clsx';
import { getRoutePrefix } from 'features/common/utils';

// we are hardcoding it as it won't change that often
const prefix = getRoutePrefix();
export const appLinks = [
  {
    id: 1,
    displayName: 'Run Payroll',
    icon: 'PayrollIcon',
    isActive: false,
    link: `${prefix}/run-payroll`,
    subLinks: [],
    className: clsx(styles.LeftNavAppMenu___navLinkText),
  },
  {
    id: 2,
    displayName: 'Off-cycle Payroll',
    icon: 'PayrollOffTimeIcon',
    isActive: false,
    link: `${prefix}/offcycle-payroll`,
    subLinks: [],
    className: clsx(styles.LeftNavAppMenu___navLinkText),
  },
  {
    id: 3,
    displayName: 'Employees',
    link: `${prefix}/onboard-employees`,
    isActive: true,
    icon: 'TeamIcon',
    subLinks: [],
    className: clsx(styles.LeftNavAppMenu___navLinkText),
  },
];
