import React, { useState } from 'react';
import { LoadingOverlay } from 'spoton-lib';
import { useGetRunPayrollUrlQuery } from 'redux/reducers/Payroll/api';

export const RunPayroll = (): JSX.Element => {
  const { isLoading, data } = useGetRunPayrollUrlQuery();
  const [isIframeLoading, setLoading] = useState(true);

  return (
    <div>
      {isLoading && isIframeLoading && (
        <LoadingOverlay size="sm" hasBackgroundBlur />
      )}
      {!isLoading && !!data?.[0]?.url && (
        <iframe
          onLoad={() => setLoading(false)}
          title="view"
          src={data[0].url}
          style={{ width: '100vw', height: '100vh', border: 'none' }}
        />
      )}
    </div>
  );
};
