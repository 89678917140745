import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { EmployeeOnboarding } from 'features/OnboardEmployees/components';
import { TermsOfService, TermsAndConditions } from 'features/common/components';
import { ManageEmployeesIframe } from 'features/OnboardEmployees/components';
import { RunPayroll } from 'features/RunPayroll';
import { getRoutePrefix } from 'features/common/utils';
import { useGetServiceTermsCheckQuery } from 'redux/reducers/Payroll/api';
import { LoadingOverlay } from 'spoton-lib';
import { OffCyclePayroll } from 'features/OffCyclePayroll/components';
import { GustoAuthentication } from '../../features/common/components/GustoAuthentication/GustoAuthentication.component';
import { GustoIsAdminComponent } from '../../features/common/components/GustoIsAdmin/GustoIsAdmin.component';

const prefix = getRoutePrefix();
function RenderNotFound(loading: boolean): JSX.Element {
  return loading ? (
    <LoadingOverlay size="sm" hasBackgroundBlur />
  ) : (
    <Redirect
      to={{
        pathname: `${prefix}/run-payroll`,
      }}
    ></Redirect>
  );
}
// Assign to variable to avoid multiple function calls

export const useRoutes = (): JSX.Element[] => {
  const { data: isServiceTermsChecked, isLoading } =
    useGetServiceTermsCheckQuery();
  const routes = (
    <Switch>
      <Route
        path={`${prefix}/onboard-employees`}
        exact
        render={() => {
          if (isServiceTermsChecked?.errorCode === 401) {
            return <GustoAuthentication />;
          } else if (isServiceTermsChecked?.errorCode === 403) {
            return <GustoIsAdminComponent />;
          } else if (
            !isServiceTermsChecked?.latestTermsRetrieved &&
            isServiceTermsChecked?.mandatory
          ) {
            return <TermsOfService />;
          } else {
            return <EmployeeOnboarding />;
          }
        }}
      />
      <Route
        path={`${prefix}/run-payroll`}
        exact
        render={() => {
          if (isServiceTermsChecked?.errorCode === 401) {
            return <GustoAuthentication />;
          } else if (isServiceTermsChecked?.errorCode === 403) {
            return <GustoIsAdminComponent />;
          } else if (
            !isServiceTermsChecked?.latestTermsRetrieved &&
            isServiceTermsChecked?.mandatory
          ) {
            return <TermsOfService />;
          } else {
            return <RunPayroll />;
          }
        }}
      />
      <Route
        path={`${prefix}/manage-employees`}
        exact
        render={() => {
          if (isServiceTermsChecked?.errorCode === 401) {
            return <GustoAuthentication />;
          } else if (isServiceTermsChecked?.errorCode === 403) {
            return <GustoIsAdminComponent />;
          } else if (
            !isServiceTermsChecked?.latestTermsRetrieved &&
            isServiceTermsChecked?.mandatory
          ) {
            return <TermsOfService />;
          } else {
            return <ManageEmployeesIframe />;
          }
        }}
      />
      <Route
        path={`${prefix}/offcycle-payroll`}
        exact
        render={() => {
          if (isServiceTermsChecked?.errorCode === 401) {
            return <GustoAuthentication />;
          } else if (isServiceTermsChecked?.errorCode === 403) {
            return <GustoIsAdminComponent />;
          } else if (
            !isServiceTermsChecked?.latestTermsRetrieved &&
            isServiceTermsChecked?.mandatory
          ) {
            return <TermsOfService />;
          } else {
            return <OffCyclePayroll />;
          }
        }}
      />
      <Route
        path={`${prefix}/TermsAndConditions`}
        exact
        render={() => <TermsAndConditions />}
      />
      <Route component={() => RenderNotFound(!!isLoading)} />
    </Switch>
  );

  return [routes];
};
