import React from 'react';
import { useLocation } from 'react-router-dom';

export interface ILocation {
  state?: string;
}
export const ManageEmployeesIframe = (): JSX.Element => {
  const flowUrl = useLocation() as ILocation;
  return (
    <iframe
      style={{ width: '100vw', height: '100vh', border: 'none' }}
      src={flowUrl?.state}
      title="Manage-employees"
    ></iframe>
  );
};
