export interface ICheckBox {
  text: any;
  checked: boolean;
}
export const resetCheckBoxes = (boxes: ICheckBox[]): ICheckBox[] => {
  return [...boxes].map(box => {
    return { ...box };
  });
};

export const updateCheckBoxHelper = (
  index: number,
  checkBoxes: ICheckBox[],
  checkTotal: number,
): { checkTotal: number; boxes: ICheckBox[] } => {
  const boxes = [...checkBoxes];
  if (boxes[index].checked === false) {
    checkTotal += 1;
  } else {
    checkTotal -= 1;
  }
  boxes[index].checked = !boxes[index].checked;

  return {
    checkTotal,
    boxes,
  };
};
