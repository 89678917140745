import React from 'react';
import { Checkbox, ContextualSaveBar } from 'spoton-lib';
import { TermsOfServiceText } from './TermsOfServiceText.component';
import { updateCheckBoxHelper, resetCheckBoxes } from 'features/common/utils';
import { useUpdateServiceTermsMutation } from 'redux/reducers/Payroll/api';
import styles from './TermsOfService.module.scss';
import { TermsAndCondText } from './TermsAndCondText';

const checkBoxData = [
  {
    text: TermsAndCondText,
    checked: false,
  },
];
export const TermsOfService = (): JSX.Element => {
  const [checkBoxes, setBoxes] = React.useState([...checkBoxData]);
  const [checkTotal, setCheckTotal] = React.useState(0);
  const [upDateTOS] = useUpdateServiceTermsMutation();

  React.useEffect(() => {
    const boxes = resetCheckBoxes(checkBoxData);
    setBoxes(boxes);
  }, []);

  const updateCheckBoxes = (index: number) => {
    const updatedBoxes = updateCheckBoxHelper(index, checkBoxes, checkTotal);
    setCheckTotal(updatedBoxes.checkTotal);
    setBoxes(updatedBoxes.boxes);
  };

  const updateAcceptanceTerms = () => {
    upDateTOS();
    window.location.reload();
  };
  return (
    <div data-testid="TermsOfServiceComponent">
      <div className={styles.TermsOfService_component}>
        <TermsOfServiceText />
        {checkBoxes.map((box, index) => {
          return (
            <Checkbox
              data-testid="CheckBox"
              key={index}
              checked={box.checked}
              label={box.text}
              onChange={() => updateCheckBoxes(index)}
              size="large"
            />
          );
        })}
      </div>
      {checkTotal === checkBoxes.length && (
        <ContextualSaveBar
          data-testid="AcceptButton"
          actions={[
            {
              onClick: () => {
                updateAcceptanceTerms();
              },
              title: 'Accept & Submit',
              variant: 'primary',
            },
          ]}
        />
      )}
    </div>
  );
};
