import React from 'react';
import clsx from 'clsx';

import { OnboardingAlert } from '../OnboardingAlert/OnboardingAlert.component';
import { IEmployeeItem } from '../../types';
import styles from './RenderAvatarCell.module.scss';

export const RenderAvatarCell = ({
  firstName,
  lastName,
  className,
  integration,
}: IEmployeeItem & { className?: string }): JSX.Element => {
  return (
    <div className={clsx(styles.DataRow, className)}>
      <div className={styles.DataRow_avatar}>
        <span data-testid="RenderAvatarCell_avatar-name">
          {firstName.toUpperCase()}
        </span>
        <span>{lastName.toUpperCase()}</span>
      </div>
      <div className={styles.DataRow_name}>
        <div data-testid="RenderAvatarCell_row-name">
          {firstName} {lastName}
          {!integration?.externalId ? (
            <OnboardingAlert className={styles.DataRow_alert} />
          ) : null}
        </div>
      </div>
    </div>
  );
};
