import React from 'react';
import styles from '../TermsOfService/TermsOfService.module.scss';
export const GustoIsAdminComponent = (): JSX.Element => {
  return (
    <div className={styles.TermsOfService_component}>
      <span className={styles.TermsOfService_termsOfServHeader}>
        You are not authorised to view this page
      </span>
      <div className={styles.TermsOfService_mainTextContainer}>
        <p className={styles.TermsOfService_paragraphText}>
          Only a Gusto admin is authorised to view this page. An existing Gusto
          admin can add additional admins from the Gusto dashboard.
        </p>
      </div>
    </div>
  );
};
