import { deepFind, sortStrings } from 'features/common/utils';

export const sortingAsc = <T>(
  dataKey: string,
  data: T[],
  accessor?: (data: T) => string,
): T[] => {
  if (!dataKey || !data?.length) return data;

  return [...data].sort((el1, el2) => {
    // at first look for accessor (provided manually, whenever we can)
    if (accessor) {
      const d1Accessor = accessor(el1);
      const d2Accessor = accessor(el2);

      return sortStrings(d1Accessor, d2Accessor);
    }

    // we sort by all properties joined, so if dataKey is ex. "firstName+lastName"
    // we are sorting string as `firstName lastName`
    const isGlued = dataKey.indexOf('+') > 0;
    if (isGlued) {
      const accessorString = dataKey.split('+');
      const firstByAccessor = accessorString
        .map(acc => deepFind(el1, acc))
        .join(' ');

      const secondByAccessor = accessorString
        .map(acc => deepFind(el2, acc))
        .join(' ');

      return sortStrings(firstByAccessor, secondByAccessor);
    }

    const paths = dataKey.split('.');
    const pathToLookAt: string = paths.length > 1 ? paths[0] : dataKey;

    // if dataKey is ex. 'titles.name' we search for 'titles' property
    const propertyValue1 = deepFind(el1, pathToLookAt) as string;
    const propertyValue2 = deepFind(el2, pathToLookAt) as string;

    // and depending on type of found property we do proper sorting
    if (Array.isArray(propertyValue1) && Array.isArray(propertyValue2)) {
      const valueType = typeof propertyValue1[0];

      if (valueType === 'object') {
        const prop = paths[1];

        const v1 = propertyValue1
          .map(e => e[prop])
          .sort()
          .join('');

        const v2 = propertyValue2
          .map(e => e[prop])
          .sort()
          .join('');

        return sortStrings(v1, v2);
      }

      if (valueType === 'string') {
        return sortStrings(propertyValue1[0], propertyValue2[0]);
      }
    }

    switch (typeof propertyValue1) {
      case 'string': {
        return sortStrings(propertyValue1, propertyValue2);
      }
      case 'boolean': {
        if (propertyValue1 === propertyValue2) return 0;
        return propertyValue1 ? 1 : -1;
      }
      case 'number': {
        return propertyValue1 > propertyValue2 ? 1 : -1;
      }
      default:
        return 0;
    }
  });
};

export const sortingDesc = <T>(
  dataKey: string,
  data: T[],
  accessor?: (data: T) => string,
): T[] => {
  return sortingAsc(dataKey, data, accessor).reverse();
};
