// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SortOptions_SortOptions_buttonWrapper__9iKMM{padding:16px 24px}.SortOptions_SortOptions_applyButton__3ccn2{width:100%;font-size:24px}.SortOptions_SortOptions_divider__2ZtDD{margin:0}\n", "",{"version":3,"sources":["webpack://src/features/common/components/Sorter/SortOptions/SortOptions.module.scss"],"names":[],"mappings":"AACE,8CACE,iBAAkB,CACnB,4CAGC,UAAW,CACX,cAAe,CAChB,wCAGC,QAAS","sourcesContent":[".SortOptions {\n  &_buttonWrapper {\n    padding: 16px 24px;\n  }\n\n  &_applyButton {\n    width: 100%;\n    font-size: 24px;\n  }\n\n  &_divider {\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SortOptions_buttonWrapper": "SortOptions_SortOptions_buttonWrapper__9iKMM",
	"SortOptions_applyButton": "SortOptions_SortOptions_applyButton__3ccn2",
	"SortOptions_divider": "SortOptions_SortOptions_divider__2ZtDD"
};
export default ___CSS_LOADER_EXPORT___;
