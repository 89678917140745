// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Test_Test__1wl1F{text-align:center}.Test_Test___modifier__a-dA1{text-align:right}.Test_Test_element__3fWhc{font-size:1.5rem}.Test_TestLogo__echXw{height:40vmin;pointer-events:none}@media (prefers-reduced-motion: no-preference){.Test_TestLogo__echXw{-webkit-animation:Test_TestLogoSpin__29z03 infinite 20s linear;animation:Test_TestLogoSpin__29z03 infinite 20s linear}}.Test_TestLink__39-PY{color:#61dafb}@-webkit-keyframes Test_TestLogoSpin__29z03{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes Test_TestLogoSpin__29z03{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}\n", "",{"version":3,"sources":["webpack://src/app/components/Test/Test.module.scss"],"names":[],"mappings":"AAAA,kBACE,iBAAkB,CAElB,6BACE,gBAAiB,CAClB,0BAGC,gBAAiB,CAClB,sBAID,aAAc,CACd,mBAAoB,CAEpB,+CAJF,sBAKI,8DAAA,CAAA,sDAA2C,CAE9C,CAED,sBACE,aAAc,CACf,4CAGC,KACE,8BAAA,CAAA,sBAAuB,CAGzB,GACE,gCAAA,CAAA,wBAAyB,CAAA,CAR5B,oCAGC,KACE,8BAAA,CAAA,sBAAuB,CAGzB,GACE,gCAAA,CAAA,wBAAyB,CAAA","sourcesContent":[".Test {\n  text-align: center;\n\n  &___modifier {\n    text-align: right;\n  }\n\n  &_element {\n    font-size: 1.5rem;\n  }\n}\n\n.TestLogo {\n  height: 40vmin;\n  pointer-events: none;\n\n  @media (prefers-reduced-motion: no-preference) {\n    animation: TestLogoSpin infinite 20s linear;\n  }\n}\n\n.TestLink {\n  color: #61dafb;\n}\n\n@keyframes TestLogoSpin {\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Test": "Test_Test__1wl1F",
	"Test___modifier": "Test_Test___modifier__a-dA1",
	"Test_element": "Test_Test_element__3fWhc",
	"TestLogo": "Test_TestLogo__echXw",
	"TestLogoSpin": "Test_TestLogoSpin__29z03",
	"TestLink": "Test_TestLink__39-PY"
};
export default ___CSS_LOADER_EXPORT___;
