import { useState } from 'react';
import { SortDirectionType } from 'spoton-lib';

import { sortingAsc, sortingDesc } from './sorting';
import {
  ISortInitial,
  IOnSort,
  IUseSort,
  IUseSortReturn,
} from './useSort.interface';

export const useSort = <T>({
  data,
  itemsPerPage = 6,
}: IUseSort<T>): IUseSortReturn<T> => {
  const [state, setState] = useState<ISortInitial<T>>({
    sortBy: 'name',
    sortDirection: 'ASC' as SortDirectionType,
    currentPage: 1,
  });

  const onSort = ({ sortBy, sortDirection, accessor }: IOnSort<T>) => {
    setState((state: ISortInitial<T>) => ({
      ...state,
      accessor,
      sortBy,
      sortDirection,
    }));
  };

  const setCurrentPage = (nextPage: number) => {
    setState((state: ISortInitial<T>) => ({
      ...state,
      currentPage: nextPage,
    }));
  };

  const { currentPage, sortBy, sortDirection, accessor } = state;

  const pageCount = Math.ceil(data.length / itemsPerPage);
  const dataOffsetStart = itemsPerPage * (currentPage - 1);

  const sortingFunc = sortDirection === 'DESC' ? sortingDesc : sortingAsc;
  const sortedData = sortingFunc<T>(sortBy, data, accessor).slice(
    dataOffsetStart,
    itemsPerPage * currentPage,
  );

  return {
    onSort,
    sortBy,
    sortedData,
    sortDirection,
    setCurrentPage,
    currentPage,
    pageCount,
  };
};
