/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';

import RootComponent from './index';

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: RootComponent,
  errorBoundary() {
    return <div>Oops! Something went wrong.</div>;
  },
});

// Single SPA lifecycle methods
export const bootstrap = reactLifecycles.bootstrap;
export const mount = reactLifecycles.mount;
export const unmount = reactLifecycles.unmount;
