import { ITranslation } from 'i18nConfig';
import translationEN from './en.json';
import translationES from './es.json';

export const translations: ITranslation = {
  namespace: 'common',
  translations: {
    en: translationEN,
    es: translationES,
  },
};

// this is the default namespace to use!
export default translations.namespace;
