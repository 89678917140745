// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RenderEmail_Tooltip__1ZUWn{display:flex;align-items:center;overflow:hidden}\n", "",{"version":3,"sources":["webpack://src/features/OnboardEmployees/components/RenderEmail/RenderEmail.module.scss"],"names":[],"mappings":"AAAA,4BACE,YAAa,CACb,kBAAmB,CACnB,eAAgB","sourcesContent":[".Tooltip {\n  display: flex;\n  align-items: center;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Tooltip": "RenderEmail_Tooltip__1ZUWn"
};
export default ___CSS_LOADER_EXPORT___;
