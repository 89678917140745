import React, { useEffect, useState } from 'react';
import {
  LeftNavVerticalsMenu,
  LeftNavAppMenu,
  LeftNavSettings,
  // LeftNavHeader,
  // Icon,
} from 'spoton-lib';

import { useHistory } from 'react-router-dom';

import { Logger } from 'features/common/utils/logger.utils';
import 'i18nConfig/i18n.config';

import { useRoutes } from 'app/routes';
import { appLinks } from './appLinks';
import styles from './App.module.scss';
import { ILink, ISubLink } from './Link.interface';

export function App(): JSX.Element {
  const history = useHistory();

  const appName = 'Payroll';

  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);
  const [isVerticalsMenuOpen, setIsVerticalsMenuOpen] = useState(false);
  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);

  const [mainLinks, setLinks] = useState(appLinks);

  useEffect(() => {
    const currentLocation = history.location.pathname.replace('/', '');

    setLinks(() =>
      appLinks.map(link => {
        link.isActive = link.link === currentLocation;
        return link;
      }),
    );
  }, []);

  function handleAppMenuLinkSelection(link: ILink | ISubLink): void {
    setLinks(
      mainLinks.map(_link => {
        _link.isActive = _link.id === link.id;
        return _link;
      }),
    );

    if (!(link as ILink).subLinks || (link as ILink).subLinks.length === 0) {
      link.link && history.push(link.link);
    }
  }

  function handleAppMenuClose(): void {
    setIsMainMenuOpen(false);
  }

  function handleAppMenuOpenVerticalsMenu(): void {
    setIsVerticalsMenuOpen(true);
  }

  function handleVerticalsMenuSelection(link: any): void {
    Logger.debug('handle verticals link selection', link);
  }

  function handleVerticalsMenusClose(): void {
    setIsVerticalsMenuOpen(false);
  }

  function handleSettingsSelection(link: any): void {
    Logger.debug('handle settings link selection', link);
  }

  function handleSettingsClose(): void {
    setIsSettingsMenuOpen(false);
  }

  function renderWithNavBar() {
    const [routes] = useRoutes();
    return (
      <>
        {/* comment for prod */}
        {/* <LeftNavHeader
          applicationName="Spoton Lib"
          currentPageName="Left Nav Header"
          userName="Brooklyn Simmons"
          userNameInitials="BS"
          userAddress="Wallaby Street #42, Sydney"
          toggleMainMenu={() => ''}
          toggleSettingsMenu={() => ''}
          toggleVerticalsMenu={() => ''}
          secondaryActions={<Icon size={32} name="NotificationIcon" />}
        /> */}
        <div>
          <LeftNavAppMenu
            isOpen={isMainMenuOpen}
            applicationName={appName}
            applicationLinks={mainLinks}
            className={styles.LeftNavAppMenu}
            onSelectLink={handleAppMenuLinkSelection}
            onClose={handleAppMenuClose} // mobile only
            onOpenVerticalsMenu={handleAppMenuOpenVerticalsMenu} // mobile only
          />

          <LeftNavVerticalsMenu
            isOpen={isVerticalsMenuOpen}
            applicationName={appName}
            links={[]}
            onSelectLink={handleVerticalsMenuSelection}
            onClose={handleVerticalsMenusClose}
          />

          <LeftNavSettings
            isOpen={isSettingsMenuOpen}
            closeSettingsMenu={handleSettingsClose}
            settingsLinks={[]}
            onSelectLink={handleSettingsSelection}
            // just a placeholder for now
            photoUrl={''}
            avatarColor={'red'}
            userAddress={''}
            userInfo={''}
            userName={''}
            userNameInitials={''}
          />

          <div className={styles.AppWrapper}>
            <div className={styles.AppContent}>{routes}</div>
          </div>
        </div>
      </>
    );
  }

  return <div className={styles.App}>{renderWithNavBar()}</div>;
}

export default App;
