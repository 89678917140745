import React from 'react';

import styles from './TermsOfService.module.scss';

export const TermsAndCondText = (
  <div>
    <span className={styles.TermsOfService_termsAndCondText}>
      I have read and accept the{' '}
    </span>{' '}
    <a
      href="https://flows.gusto.com/terms"
      rel="noopener noreferrer"
      target="_blank"
      className={styles.TermsOfService_termsAndCondText}
    >
      <span className={styles.TermsOfService_linkColor}>
        Terms and Conditions
      </span>
    </a>
  </div>
);
